<template>
    <div>
        <div class="flex flex-wrap gap-3" v-if="!submitted">
            <div class="h-auto min-h-fit rounded-lg p-3 flex-grow">
                <div id="captureElement" class="flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2" style="height: 280px">
                    <div class="flex flex-col items-center justify-center gap-1 w-full dark:text-gray-100 py-2">
                        <label  for="htmlFile" class="w-auto flex items-center hover:bg-gray-900 bg-red-500 px-5 py-2 rounded-full shadow-lg border cursor-pointer text-white my-auto font-medium text-[22px] gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none" data-v-5d608623=""><g clip-path="url(#clip0_429_10970)" data-v-5d608623=""><circle cx="12" cy="11.999" r="9" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></circle><path d="M12 9V15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path><path d="M9 12H15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path></g><defs data-v-5d608623=""><clipPath id="clip0_429_10970" data-v-5d608623=""><rect width="24" height="24" fill="white" data-v-5d608623=""></rect></clipPath></defs></svg>
                            <span class="">Upload your Image Files</span>
                            <input type="file" @change="handleFileUpload" id="htmlFile" ref="htmlFile" accept=".html" class="hidden" />
                        </label>
                        <p class="text-base md:text-xl mt-2.5 text-gray-400 font-semibold text-black">Or drop files here.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Display image and download image -->
        <div v-if="capturedImage" class="flex flex-wrap flex-row w-full justify-between gap-2 border-2 border-gray-200 rounded-md p-2 mt-5">
            <div class="flex flex-wrap gap-2">
                <img :src="capturedImage" class="rounded-lg h-5 my-1 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px" />
            </div>
            <div class="flex">
                <div class="my-auto">
                    <a :href="capturedImage" download="captured_page.jpg" class="bg-red-800 p-1 text-white rounded hover:bg-red-500 hover:shadow-sm">Download</a>
                    
                </div>
                <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                    <button @click="remove" title="Remove file" class="inline-flex items-center top-2 right-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="13px" width="13px" viewBox="0 0 492 492">
                            <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="flex justify-end mr-2 mt-4" v-if="capturedImage">
            <button @click="captureWebpage"  class="py-2 px-3 text-sm font-medium text-white bg-red-800 rounded-md hover:bg-red-400 focus:outline-none focus:ring-4 focus:ring-gray-100">
                Convert
            </button>
        </div>

        <div class="inline-flex items-center justify-center w-full px-4">
            <hr class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700">
            <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
        </div>

        <div class="px-4">
            <p class="text-base md:text-xl font-semibold text-black mb-2">Import from URL</p>
            <form @submit.prevent="captureWebpage">
                <label for="search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative">
                    <input type="text" v-model="fileUrl" id="fileUrl" placeholder="Enter URL" class="block w-full pe-24 truncate  p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-red-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <button type="submit" class="text-black absolute  end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Upload</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
    data() {
        return {
            fileUrl: '',
            capturedImage: null,
            submitted: false,
            htmlFileContent: null,
        };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.htmlFileContent = e.target.result;
                };
                reader.readAsText(file);
            }
        },
        async captureWebpage() {
            try {
                let html = '';
                if (this.fileUrl) {
                    console.log('Fetching HTML content...');
                    const response = await fetch(`/proxy?url=${encodeURIComponent(this.fileUrl)}`);
                    html = await response.text();
                    console.log('Fetched HTML content:', html);
                } else if (this.htmlFileContent) {
                    html = this.htmlFileContent;
                } else {
                    console.error('No URL or HTML file content provided.');
                    return;
                }

                const iframe = document.createElement('iframe');
                iframe.style.position = 'absolute';
                iframe.style.top = '-9999px';
                iframe.style.width = '100%';
                iframe.style.height = '100%';
                document.body.appendChild(iframe);

                iframe.onload = async () => {
                    console.log('Iframe loaded');
                    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                    iframeDocument.open();
                    iframeDocument.write(html);
                    iframeDocument.close();

                    setTimeout(async () => {

                        const canvas = await html2canvas(iframeDocument.documentElement, {
                            useCORS: true,
                            allowTaint: true
                        });
                        this.capturedImage = canvas.toDataURL('image/jpeg');
                        console.log('Image captured:', this.capturedImage);
                        document.body.removeChild(iframe);
                    }, 3000);
                };

                iframe.src = 'about:blank';

            } catch (error) {
                console.error('Error capturing webpage:', error);
            }
        },
        async remove() {
            try {
                this.capturedImage = null; // Clear the captured image
                this.submitted = false; // Reset submitted state if needed
            } catch (error) {
                console.error('Error removing file:', error);
            }
        }

    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
