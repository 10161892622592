<template>
    <div>
        <div class="flex flex-wrap gap-3" v-if="!this.submitted">
            <div class="h-auto min-h-fit rounded-lg p-3  rounded-lg flex-grow">
                <div @drop.prevent="dropFile" @dragenter="dropFile" @dragover="dropFile" :class="{ ' bg-pink-800': dragActive }" style="height: 280px" class=" flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2">
                    <div class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2">

                        <label  for="file" class="w-auto flex items-center hover:bg-gray-900 bg-red-500 px-5 py-2 rounded-full shadow-lg border cursor-pointer text-white my-auto font-medium text-[22px] gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none" data-v-5d608623=""><g clip-path="url(#clip0_429_10970)" data-v-5d608623=""><circle cx="12" cy="11.999" r="9" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></circle><path d="M12 9V15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path><path d="M9 12H15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path></g><defs data-v-5d608623=""><clipPath id="clip0_429_10970" data-v-5d608623=""><rect width="24" height="24" fill="white" data-v-5d608623=""></rect></clipPath></defs></svg>
                            <span class="">Upload your JPG Files</span>
                            <input  type="file" multiple name="fields" @change="handleFileSelection"  ref="file" accept=".jpg,.jpeg" id="file" class="hidden" />
                        </label>
                        <p class="text-base md:text-xl mt-2.5 text-gray-400 font-semibold text-black">Or drop files here.</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- sample button-->
         <div>
            <button @click="addSampleFile"  v-if="this.files.length < 1"  type="button" class="py-2.5 w-32 ml-3 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100">
      ADD SAMPLE FILE
    </button>
    <!-- Image model preview -->
    <div v-if="result" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
  <div class="bg-white p-4 rounded-lg w-[720px]  relative"> 
    <button @click="perviewImage" v-if="this.files.length > 0" class="absolute right-0 top-1 mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center  hover:bg-red-50">
      <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="15px" width="20px" viewBox="0 0 492 492" class="">
        <g>
          <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
        </g>
      </svg>
    </button>
    <!-- Image preview -->
    <img :src="result" class="rounded-lg m-8 w-[720px] mx-auto object-contain" style="object-fit: cover;">
  </div>
</div>



  </div>
         <!-- end sample buttons -->
        <div class="flex w-full" v-if="this.files.length && !this.submitted">
            <div class="flex flex-wrap mt-4 rounded-lg p-4 w-full">
                <div class="w-full flex justify-between">
                    <h3 class="w-full">Images({{this.files.length}})</h3>
                    <div>
                        <button @click="removeAllFiles"   type="button" class="py-2.5 w-24  me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100">Cancel All</button>
                    </div>
                </div>
                <div class="w-full p-3 my-1 flex flex-wrap flex-row" v-for="(file, index) in files">
                    <div class="w-full flex flex-wrap flex-row justify-between gap-2  border-2 border-gray-200 rounded-md p-2  relative">
                        <div class="w-full flex flex-wrap flex-row justify-between">
                            <div class="flex flex-wrap gap-2">
                                <img :src="file.dataURL" class="rounded-lg my-2 h-5 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px">
                                <div class="flex flex-col my-auto">
                                    <span class="text-xs font-semibold text-red-600 my-2">{{ file.name.substr(file.name.length - 10) }}</span>
                                    <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                                </div>
                            </div>
                            <div class="flex">
                                <p class="my-auto text-base font-semibold mx-2">Output</p>
                                <select id="select" class="w-[77px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:bg-red-50 cursor-pointer" disabled>
                                    <option value="PDF">SVG</option>
                                </select>

                                <!-- crop Button-->
                                <button @click="CropperPopupSet(index)" title="Cropper" class="inline-flex items-center top-1 right-1 my-auto">
                                        <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="22px" height="22px" viewBox="0 0 1920 1920">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M426.667 0h106.666v1386.67H1920v106.66H426.667V0zM320 426.667H0v106.666h320V426.667zm320 0v106.666h746.67V1280h106.66V426.667H640zM1493.33 1600h-106.66v320h106.66v-320z"/>
                                        </svg>
                                    </div>
                                    </button>
                                    <!-- setting button -->
                                    <button @click="settingPopup = true" title="Setting">
                                        <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    </button>
                                <!-- remove Files Button -->
                                <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center top-1 right-1 my-auto">
                                        <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- convert button -->
        <div class="flex justify-end">
                <div class="mr-5">
                    <button  v-if="files.length > 0 && !conversionCompleted" @click="uploadFiles" :disabled="disableConvertButton" :class="{ 'bg-red-300': disableConvertButton, 'bg-red-800': !disableConvertButton, 'hover:bg-red-500 hover:shadow-sm': !disableConvertButton }" class="py-2 px-3 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-800  hover:bg-red-400 focus:ring-4 focus:ring-gray-100">
                        Convert
                        <i class="fa-solid fa-arrow-right"></i>
                    </button>
                </div>

            </div>
        <div v-if="!this.submitted">
            <div class="inline-flex items-center justify-center w-full px-4">
                <hr class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700">
                <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
            </div>

            <div class="px-4">
                <p class="text-base md:text-xl font-semibold text-black mb-2">Import from URL</p>
                <form>
                    <label for="search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div class="relative">
                        <input type="text" v-model="fileUrl" id="upload" class="block w-full pe-24 truncate p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-red-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Add file URL" />
                        <button type="submit" @click="handleUpload" v-if="fileUrl" class="text-black absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Upload</button>
                    </div>
                </form>
            </div>
        </div>
        <div>
            <div class="flex flex-wrap shadow-lg mt-4 rounded-lg p-4" v-if="this.files.length && this.submitted">
                <div class="w-full flex justify-between">
                    <h3>Results({{this.files.length}})</h3>
                    <div>
                        <button @click="removeAllFiles" type="button" class="py-2.5 px-3 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100">Cancel All</button>
                    </div>

                </div>
                <div class="flex flex-wrap justify-between w-full p-3 my-1" v-for="(file,index) in files" :key="index">
                    <div class="flex flex-wrap flex-row w-full justify-between gap-2 border-2 border-gray-200 rounded-md p-2">
                        <div class="flex flex-wrap gap-2">
                            <img :src="file.dataURL" class="rounded-lg h-5 my-1 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px">
                            <div class="flex flex-col my-auto">
                                <span class="text-xs font-semibold text-red-600 my-1">{{ file.name.substr(file.name.length - 10) }}</span>
                                <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                            </div>
                        </div>
                        <div class="flex">
                         <div class="flex flex-wrap">
                               <!-- File Info -->
                               <div class="my-auto cursor-pointer">
                               <div class="flex justify-between items-center w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm py-2">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-10 h-6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 14.5V17.5M12 11.5H12.01M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                <a  
                                    @click="toggleFileInfo(index)" class="mr-3.5">FILE INFO </a>
                               </div>
                            </div>

                            <!-- Preview Button -->
                            <div class="my-auto cursor-pointer mx-2" @click="perviewImage(file)"  v-if="this.files.length >0">
                               <div class="flex justify-between items-center w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm py-2">
                                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve" stroke="#ffffff" class="w-10 h-6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M51.8,25.1C47.1,15.6,37.3,9,26,9S4.9,15.6,0.2,25.1c-0.3,0.6-0.3,1.3,0,1.8C4.9,36.4,14.7,43,26,43 s21.1-6.6,25.8-16.1C52.1,26.3,52.1,25.7,51.8,25.1z M26,37c-6.1,0-11-4.9-11-11s4.9-11,11-11s11,4.9,11,11S32.1,37,26,37z"></path> <path d="M26,19c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S29.9,19,26,19z"></path> </g> </g></svg>
                                <a  
                                    class="mr-3.5">PREVIEW</a>
                               </div>
                            </div>
                               <!-- Download button -->
                               <div class="my-auto">
                               <div class="flex justify-between items-center w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm py-2 cursor-pointer">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-10 h-6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#ffffff"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#ffffff"></path> </g></svg>
                                <a v-if="file.uploaded" target="_blank" :href="file.dataURL" :download="file.name" class="mr-3.5">DOWNLOAD</a>
                               </div>
                            </div>
                         </div>
                            <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full hover:bg-red-50 flex items-center justify-center">
                                <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center  top-2 right-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                  <!-- download all button -->
            <div class="w-full flex justify-end mr-12 mt-2">
                    <!-- Feed Back Button -->
                     <div>
                       <button @click="showFeedbackModal = true" class="ra py-1.5 px-2 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-700 hover:bg-red-400 ">
                           Feedback
                       </button>
                     </div>
                     <button v-if="conversionCompleted && files.length > 1" @click="downloadAllAsZip" class="py-1.5 px-2 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-700 hover:bg-red-400 focus:ring-4 focus:ring-gray-100">
                        Download All
                    </button>
            </div>
                    <!-- end download all -->
            </div>
            <div 
      v-if="showFeedbackModal" 
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div 
        class="relative bg-white rounded-lg shadow-lg w-full max-w-lg p-6 transform transition-all duration-300 scale-95"
      >
        <!-- Modal Header -->
        <div class="flex  justify-between items-center border-b pb-3">
            <div class="flex">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-10 h-10"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 1C17.6569 1 19 2.34315 19 4C19 4.55228 18.5523 5 18 5C17.4477 5 17 4.55228 17 4C17 3.44772 16.5523 3 16 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H16C16.5523 21 17 20.5523 17 20V19C17 18.4477 17.4477 18 18 18C18.5523 18 19 18.4477 19 19V20C19 21.6569 17.6569 23 16 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H16Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7991 8.20087C20.4993 7.90104 20.0132 7.90104 19.7133 8.20087L11.9166 15.9977C11.7692 16.145 11.6715 16.3348 11.6373 16.5404L11.4728 17.5272L12.4596 17.3627C12.6652 17.3285 12.855 17.2308 13.0023 17.0835L20.7991 9.28666C21.099 8.98682 21.099 8.5007 20.7991 8.20087ZM18.2991 6.78666C19.38 5.70578 21.1325 5.70577 22.2134 6.78665C23.2942 7.86754 23.2942 9.61999 22.2134 10.7009L14.4166 18.4977C13.9744 18.9398 13.4052 19.2327 12.7884 19.3355L11.8016 19.5C10.448 19.7256 9.2744 18.5521 9.50001 17.1984L9.66448 16.2116C9.76728 15.5948 10.0602 15.0256 10.5023 14.5834L18.2991 6.78666Z" fill="#0F0F0F"></path> <path d="M5 7C5 6.44772 5.44772 6 6 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H6C5.44772 8 5 7.55228 5 7Z" fill="#0F0F0F"></path> <path d="M5 11C5 10.4477 5.44772 10 6 10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H6C5.44772 12 5 11.5523 5 11Z" fill="#0F0F0F"></path> <path d="M5 15C5 14.4477 5.44772 14 6 14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6C5.44772 16 5 15.5523 5 15Z" fill="#0F0F0F"></path> </g></svg>
                <h3 class="text-2xl my-auto font-medium text-gray-900">Feedback</h3>
            </div>
          <button 
            @click="showFeedbackModal = false" 
            class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
          >
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
                 </button>
        </div>
        <div class="mt-4">
          <p class="text-xl my-auto font-medium text-gray-900">
            Please share your valuable feedback!
          </p>
          <textarea 
            class="mt-2 w-full p-2 border rounded-md focus:ring-2  focus:outline-none" 
            rows="10" 
            v-model="feedback"
            placeholder="Express your feedback here..."
          ></textarea>
        </div>
        <div class="flex justify-end mt-4">
          <button 
            @click="submitFeedback" 
            class="py-1.5 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 flex justify-center items-center"
          >
            Submit
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mx-2  "><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289Z" fill="#ffffff"></path> </g></svg>
          </button>
        </div>
    </div>
    </div>
        </div>

        <div class="w-full flex flex-wrap flex-row justify-end my-2 px-2">

            
        </div>
        <!-- option pop up start.. -->
        <div v-if="settingPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-4 rounded-lg w-[720px] relative">
                <div class="flex my-4">
                    <!--cross button..-->
                    <button @click="settingPopup = false" class="inline-flex items-center top-1 right-1 my-auto absolute top-4 right-4  my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                            <g>
                                <g>
                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                </g>
                            </g>
                        </svg>
                    </button>

                    <div class="my-auto mx-2 w-10 h-10 rounded-md border-2  flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="mx-2">
                        <p class="text-lg font-semibold">Options</p>
                        <span class="text-sm font-medium">JPG to SVG Converter</span>
                    </div>
                </div>
                <hr>
                <div class="my-5">
                    <div class="mb-1 flex justify-around">
                        <label class="my-auto block text-black dark:text-white">
                            Quality <span class="text-meta-1">*</span>
                        </label>
<!--                        <input v-model="quality" type="number" id="quality" name="quality" class="w-2/3 mr-2 rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" min="0" max="1" step="1" />-->

                        <select v-model="quality" class="w-2/3">
                            <option value="1">High</option>
                            <option value="0.5">Medium</option>
                            <option value="0">Low</option>
                        </select>


                    </div>
                    <div class="w-[66%] text-left ml-[26%] mb-1">
                        <span class="text-[12px] leading-normal">
                            JPEg compression level from 1 (lowest image quality and highest compression) to 100 (best quality but least effective compression). The default is to estimate the quality based on your input image.
                        </span>
                    </div>
                </div>
                <hr>
                <div class="w-[90%] mx-auto">
                    <button @click="settingPopup = false" class="w-[46%] hover:bg-red-50  mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer">Cancel</button>
                    <button @click="settingPopup = false" class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">Confirm</button>
                </div>
            </div>
        </div>
        <!-- option pop up end.. -->

        <!-- cropper Pop up start.. -->
        <div v-if="cropperPopup" class="cropper-popup fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col">
            <div class="w-[720px] relative">
                <button class="inline-flex items-center my-auto absolute top-6 right-6 z-50 px-3 py-3 hover:bg-red-50 rounded-full  bg-white" @click="cropperPopup = false">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <div class="bg-white p-4 rounded-lg w-[720px] relative">
                <VuePictureCropper
                    :aspectRatio="1/1"
                    :boxStyle="{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#f8f8f8',
                        margin: 'auto'
                    }"
                    :img="pic"
                    :options="options"
                    @cropend="getResult"
                    ref="cropperRef"
                />

                <div class="flex flex-wrap justify-center flex-col">
                    <div class="inline-flex rounded-md shadow-sm my-2" role="group">
                        <button @click="rotateTo(-45)" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" d="M20.4852814,14 L12,22.4852814 L3.51471863,14 L12,5.51471863 L20.4852814,14 Z M6.34314575,14 L12,19.6568542 L17.6568542,14 L12,8.34314575 L6.34314575,14 Z M4.15672797,4.28593933 C6.18580699,2.22227176 8.98673097,1 12,1 C15.9721115,1 19.5752376,3.12390371 21.5277839,6.49927404 L19.7965722,7.50072596 C18.1979627,4.73720691 15.2508538,3 12,3 C9.38603956,3 6.96846584,4.12319378 5.29042786,6 L8,6 L8,8 L2.40310647,8 L2.00227469,2.0674107 L3.99772531,1.9325893 L4.15672797,4.28593933 Z"></path> </g></svg>
                            Rotate Left
                        </button>
                        <button @click="reset" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-3 h-3"  fill="currentColor" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M960 0v213.333c411.627 0 746.667 334.934 746.667 746.667S1371.627 1706.667 960 1706.667 213.333 1371.733 213.333 960c0-197.013 78.4-382.507 213.334-520.747v254.08H640V106.667H53.333V320h191.04C88.64 494.08 0 720.96 0 960c0 529.28 430.613 960 960 960s960-430.72 960-960S1489.387 0 960 0" fill-rule="evenodd"></path> </g></svg>
                            Reset
                        </button>
                        <button @click="rotateTo(45)"  type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" d="M3.04477723,14 L11.5300586,5.51471863 L20.01534,14 L11.5300586,22.4852814 L3.04477723,14 Z M17.1869129,14 L11.5300586,8.34314575 L5.87320435,14 L11.5300586,19.6568542 L17.1869129,14 Z M19.3733306,4.28593933 L19.5323333,1.9325893 L21.5277839,2.0674107 L21.1269521,8 L15.5300586,8 L15.5300586,6 L18.2396307,6 C16.5615928,4.12319378 14.144019,3 11.5300586,3 C8.27920479,3 5.33209587,4.73720691 3.73348642,7.50072596 L2.00227469,6.49927404 C3.954821,3.12390371 7.5579471,1 11.5300586,1 C14.5433276,1 17.3442516,2.22227176 19.3733306,4.28593933 Z"></path> </g></svg>
                            Rotate Right
                        </button>
                    </div>
                    <div class="inline-flex rounded-md shadow-sm my-2" role="group">
                        <button @click="flipY" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                            <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 13 5 c 0 -0.265625 -0.105469 -0.519531 -0.292969 -0.707031 l -4 -4 c -0.390625 -0.3906252 -1.023437 -0.3906252 -1.414062 0 l -4 4 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 2.292969 -2.292969 v 9.171876 l -2.292969 -2.292969 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 s -0.390625 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 l 4 -4 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 s -0.105469 -0.519531 -0.292969 -0.707031 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 l -2.292969 2.292969 v -9.171876 l 2.292969 2.292969 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 z m 0 0" fill="currentColor"></path> </g></svg>
                            Flip Vertical
                        </button>
                        <button @click="flipX"  type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-l-0 border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 5 3 c -0.265625 0 -0.519531 0.105469 -0.707031 0.292969 l -4 4 c -0.3906252 0.390625 -0.3906252 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 s 0.390625 -1.023437 0 -1.414062 l -2.292969 -2.292969 h 9.171876 l -2.292969 2.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 4 -4 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 l -4 -4 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 s -0.519531 0.105469 -0.707031 0.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 l 2.292969 2.292969 h -9.171876 l 2.292969 -2.292969 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 z m 0 0" fill="currentColor"></path> </g></svg>
                            Flip Horizontal
                        </button>
                    </div>

                </div>
                <div class="w-[90%] mx-auto">
                    <button @click="cropperPopup = false" class="w-[46%] mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer hover:bg-red-50">Cancel</button>
                    <button @click="confirmCrop" class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">Confirm</button>
                </div>
            </div>
            </div>
        </div>
        <!-- cropper Pop up end.. -->

    </div>
    <div class="flex flex-wrap">
    <table 
v-if="  showFileInfo !== null"
    class="table-auto border-collapse flex-wrap border border-gray-200 w-full mt-4 text-left"
  >
  <thead class="bg-gray-100">
      <tr>
        <th class="border px-4 py-2">File Name</th>
        <th class="border px-4 py-2">File Size</th>
        <th class="border px-4 py-2">File Type</th>
        <th class="border px-4 py-2">File Extension</th>
        <th class="border px-4 py-2">File Image</th>
      </tr>
    </thead>
    <tbody>
        <tr v-for="(file, index) in files" :key="index">
            <td v-if="showFileInfo === index" class="border px-4 py-2">{{ file.name }}</td>
            <td v-if="showFileInfo === index" class="border px-4 py-2">{{ (file.size / 1024).toFixed(2) }} KB</td>
            <td v-if="showFileInfo === index" class="border px-4 py-2">{{ file.type }}</td>
            <td v-if="showFileInfo === index" class="border px-4 py-2 text-center">{{ file.extention||"svg" }}</td>
            <td v-if="showFileInfo === index" class="border px-4 py-2">  <img :src="file.dataURL" class="rounded-lg my-2 h-5 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px" /></td>
          </tr>
    </tbody>
  </table>
   </div>
</template>

<script>
import { ref, computed  } from "vue";
import axios from "axios";
import JSZip from 'jszip';
import VuePictureCropper, { cropper }  from 'vue-picture-cropper';
import 'cropperjs/dist/cropper.css';

export default{
    props: {
        translations:Object,
        files: Array
    },
    components: {
        VuePictureCropper,
    },
    data() {
        return {
            options: {
                viewMode: 2,
                dragMode: 'crop',
                aspectRatio: NaN,
            },
            cropperPopup: false,
            pic: '',
            result: null,
            files: [],
            showFileInfo: null,
            result:null,
            removeAllFiles:false,
            currentFileIndex: null,
            convertedPdfUrl: null,
            quality: 1,
            showFeedbackModal:false,
            feedback:'',
            imageUrl:'/images/jpg-logo.jpg'
        };
    },
    methods: {
        handleFileSelection(event) {
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                type: file.type,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
                pdfURL: null,
                convertedPdfUrl: null,
            }));

            for (const file of newFiles) {
                this.uploadFile(file, this.quality); // Convert each file to PDF
                console.log("Uploading", file)
            }

            this.files.push(...newFiles);
        }, 
        submitFeedback(){
             this.feedback
             this.showFeedbackModal=false;
             this.feedback=""
        }, 
            // sample image file funcation
addSampleFile(){
   this.files.push({
    name:"sample.jpg",
    type:"image/jpg",
    dataURL:this.imageUrl,
    file: { size: 1024 }
   });
},
toggleFileInfo(index) {
    this.showFileInfo = this.showFileInfo === index ? null : index;
},

perviewImage(file) {
    this.result = file.dataURL;       
    }, 
        rotateTo(e) {
            cropper.rotate(e)
            // Reset last selection and results
        },

        flipX() {
            let flipp = this.flipXValue === true?1:-1;
            cropper.scaleX(flipp)
            this.flipXValue = !this.flipXValue;
        },
        flipY() {
            let flipp = this.flipYValue === true?1:-1;
            cropper.scaleY(flipp)
            this.flipYValue = !this.flipYValue;
        },

        getResult(result) {
            this.result = result;
        },

        reset() {
            if (!cropper) return
            cropper.reset()
        },

    },
    setup() {
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);
        const settingPopup = ref(false);
        const cropperPopup = ref(false);
        const conversionCompleted = ref(false);
        const disableConvertButton = ref(false);
        const fileUrl = ref("");
        const quality = ref(1);
        const convertedPdfUrl = ref("");
        const currentFileIndex = ref(null);
        const pic = ref('');
        const result = ref(null);
        const showFileInfo = ref(null);
        const handleFileSelection = (event) =>{
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name:file.name,
                type: file.type,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file:file, // Store the actual File object
            }));

            files.value.push(...newFiles);
            disableConvertButton.value = false;
        };

        const removeAllFiles = () => {
            files.value = []; // Empty the files array
            submitted.value = false;
            conversionCompleted.value = false; // Reset conversion completed flag
            showFileInfo.value = null; 
            result.value=null;
            
        };
        const uploadFiles = async () => {
            submitted.value=true;
            for (let i = 0;i<files.value.length;i++){
                if (files.value[i].uploaded) {
                    continue;
                }
                await uploadFile(files.value[i]);
                conversionCompleted.value = true;
            }
        };

        const download =async(index) => {
            alert('download')
        };

        const downloadAllAsZip = async () => {
            const zip = new JSZip();

            for (const file of files.value) {
                const response = await fetch(file.dataURL);
                const blob = await response.blob();
                zip.file(file.name, blob);
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(zipBlob);

            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'converted_files.zip';

            // Append the anchor to the body (not visible)
            document.body.appendChild(a);

            // Programmatically click the anchor to trigger the download
            a.click();

            // Remove the anchor from the document
            document.body.removeChild(a);

            // Revoke the Blob URL to release memory
            URL.revokeObjectURL(url);
        };

        const showDownloadAllButton = computed(() => {
            const jpgFiles = files.value.filter(file => file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg'));
            return jpgFiles.length > 1;
        });
        const remove =async(index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value =false;
                conversionCompleted.value = false;
            showFileInfo.value = null; 
            files.value=[];
            }
        };

        const uploadFile = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            try {
                const reader = new FileReader();
                reader.onload = () => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.width;
                        canvas.height = img.height;

                        ctx.drawImage(img, 0, 0, img.width, img.height);

                        let qualityValue = 1;

                        if (quality.value === '0.5') {
                            qualityValue = 0.3;
                        } else if (quality.value === '0') {
                            qualityValue = 0.1; // Low quality
                        }
                        const dataURL = canvas.toDataURL('image/jpeg', qualityValue);

                        const svgString = `<svg width="${img.width}" height="${img.height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><image xlink:href="${dataURL}" width="${img.width}" height="${img.height}" /></svg>`;

                        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });

                        setTimeout(() => {
                            file.uploadProgress = 100;
                            file.uploading = false;
                            file.uploaded = true;

                            file.dataURL = URL.createObjectURL(svgBlob);
                            file.name = file.name.replace(/\.[^/.]+$/, "") + ".svg";

                            console.log('Quality value after conversion:', quality.value);
                        }, 500);
                    };
                    img.src = reader.result;
                };
                reader.readAsDataURL(file.file);
            } catch (error) {
                console.error("Error converting image:", error);
                file.uploading = false;
                file.error = "Error converting image";
            }
        };

        const confirmCrop = async () => {
            const dataURL = cropper.getDataURL();
            console.log("Cropped Image Data URL:", dataURL);

            // Update the Data URL and the file object in the files array
            const croppedBlob = dataURLtoBlob(dataURL);
            const newFileName = files.value[currentFileIndex.value].name.replace(/\.[^/.]+$/, '') + '_cropped.png';

            files.value[currentFileIndex.value].dataURL = dataURL;
            files.value[currentFileIndex.value].file = new File([croppedBlob], newFileName, { type: croppedBlob.type });
            files.value[currentFileIndex.value].name = newFileName;

            cropperPopup.value = false;
        };

        const dataURLtoBlob = (dataURL) => {
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], { type: mimeString });
        };

        const CropperPopupSet = (index) => {
            currentFileIndex.value = index;
            cropperPopup.value = true;
            pic.value = files.value[currentFileIndex.value].dataURL;
            console.log(files.value[currentFileIndex.value].dataURL);
        };

        const uploadFileOld = async(file) => {
            const formData = new FormData();
            formData.append("convert",'to-svg');
            formData.append("images",file.file); // Append the actual File object

            const config={
                headers:{"Content-Type":"multipart/form-data"},
                onUploadProgress:(progressEvent) =>{
                    const progress = Math.round(
                        (progressEvent.loaded/progressEvent.total) * 100
                    );
                    file.uploading=true;
                    file.uploadProgress = progress;
                },
            };

            try {
                // Replace 'your-api-endpoint' with your actual server API endpoint
                const response = await axios.post("/file-upload-Svg",formData, config);
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress=100;
                file.responseData=response.data;
                file.downloadPath = response.data.path;
            } catch (error){
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);
            }
        };
        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };

        const handleUpload = async (event) => {
            event.preventDefault();
            const isImage = await checkImageURL(fileUrl.value);
            if (!isImage) {
                alert('Invalid format: Only image URLs are allowed.');
                return;
            }
            try {
                const response = await fetch(fileUrl.value);
                if (!response.ok) {
                    console.error('Error:', response.status, response.statusText);
                    alert('Error fetching image from the URL.');
                    return;
                }

                const blob = await response.blob();
                const file = new File([blob], fileUrl.value.substring(fileUrl.value.lastIndexOf('/') + 1), { type: blob.type });

                const imageUrl = URL.createObjectURL(blob);
                const fileObject  = {
                    dataURL: imageUrl,
                    name: file.name,
                    type: blob.type,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                };
                files.value.push(fileObject);
                disableConvertButton.value = false;
                   fileUrl.value = "";
            } catch (error) {
                console.error('Error handling image upload:', error);
            }
        };

        const checkImageURL = async (url) => {
            console.log('Checking image URL:', url);
            const response = await fetch(url);
            console.log('Fetch response:', response);
              url=""
            if (!response.ok) {
                console.error('Error:', response.status, response.statusText);
                throw new Error('Failed to fetch image.');
            }
              
            const contentType = response.headers.get('content-type');
            console.log('Content-Type:', contentType);
            if (contentType && (contentType.startsWith('image/') || contentType.startsWith('img/'))) {

                const imageData = await response.blob();
                console.log('Image Data:', imageData);
                if (imageData && imageData.type.startsWith('image/')) {
                    console.log('Image URL is valid.');
                    return true;
                } else {
                    throw new Error('Response data is not an image.');
                }
            } else {
                throw new Error('Invalid content type for image.');
            }
        };

        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value=false;
            const newFiles = Array.from(event.dataTransfer.files).map((file) => ({
                dataURL:URL.createObjectURL(file),
                name:file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            dragActive,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            settingPopup,
            cropperPopup,
            downloadAllAsZip,
            conversionCompleted,
            showDownloadAllButton,
            removeAllFiles,
            disableConvertButton,
            fileUrl,
            handleUpload,
            quality,
            convertedPdfUrl,
            CropperPopupSet,
            confirmCrop,
            pic,
            dataURLtoBlob,
            showFileInfo,
        };
    },
};

</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}
.alert-box-close {
    border-radius: 22px;
    width: 132px;
    height: 38px;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
}
#dropdown {
    position: absolute;
    top: calc(100% + 10px);
    right: 130px;
    z-index: 10;
}
.pdf-viewer {
    width: 100%;
    height: 500px; /* Adjust height as needed */
}
.cropper-cross{
    position: relative;
    top: 9%;
    left: 22.5%;
}
</style>
